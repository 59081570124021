import { Text } from '@doda/io-react/common';
import { JobDetailStatus } from 'components/RecommendCard/components/JobDetailStatus';
import styles from './index.module.scss';
import { omitCompanyName } from '../../logics/omitCompanyName';

/**
 * CompanyNameコンポーネントのProps
 */
interface Props {
  /** 会社名 */
  companyName: string;
  /** 新着フラグ（任意） */
  isNew?: boolean;
  /** 締切フラグ（任意） */
  isDeadline?: boolean;
  /** 人気求人ランキングページ用タグ変更フラグ（任意） */
  isJobRankingPage?: boolean;
}

/**
 * CompanyNameコンポーネント
 * @param {Props} props - コンポーネントのプロパティ
 * @returns {JSX.Element} CompanyNameのJSX要素
 */
export const CompanyName: React.FC<Props> = ({
  companyName,
  isNew,
  isDeadline,
  isJobRankingPage,
}) => {
  const omitedCompanyName = omitCompanyName(companyName, isNew, isDeadline);
  const textTag = isJobRankingPage ? 'h3' : 'span';
  return (
    <Text
      as={textTag}
      weight="bold"
      size="14--articlePC"
      color="blue"
      className={styles.cardDetail__companyName}
    >
      {omitedCompanyName}
      {(isNew || isDeadline) && (
        <JobDetailStatus isNew={isNew} isDeadline={isDeadline} />
      )}
    </Text>
  );
};
