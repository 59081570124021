import { SectionTitle, Text } from '@doda/io-react/common';
import styles from './index.module.scss';

/**
 * レコメンドスライドのタイトル用Props
 */
interface Props {
  title: string;
  jobCount?: number;
  isNewArrival?: boolean;
  /** 人気求人ランキングページ用タグ変更フラグ（任意） */
  isJobRankingPage?: boolean;
}

export const Title: React.FC<Props> = ({
  title,
  jobCount,
  isNewArrival,
  isJobRankingPage,
}) => {
  if (isJobRankingPage) {
    return (
      <Text as="h2" size="20" color="black_pc" weight="bold">
        {title}
      </Text>
    );
  }

  return isNewArrival && jobCount ? (
    <div>
      <div className={styles.recommendSliderTitle}>
        新着求人
        <span className={styles.recommendSliderTitle_jobCount}>
          {jobCount.toLocaleString()}
        </span>
        件
      </div>
      <p className={styles.recommendSliderUpdateDate}>
        毎週月・木曜に新着求人をお知らせ
      </p>
    </div>
  ) : (
    <SectionTitle bgType="none" size="XL">
      {title}
    </SectionTitle>
  );
};
